import * as React from 'react'

import type { NextPage } from '@grandvisionhq/next'
import { compose } from '@grandvisionhq/utils'
import { Page } from '@grandvisionhq/www-next/pages/my-account/login'

import withCms from '../../../cms/with-cms'
import withLayout from '../../../cms/with-layout'
import { withCart } from '../../../components/hocs/with-cart'

import '@grandvisionhq/my-account/dist/index.css'

const LoginPage: NextPage = () => <Page />

export default compose(withCms(), withLayout(), withCart({ readOnly: true }))(LoginPage)
