import getConfig from 'next/config'

import { CartSettings, InjectedCartProps, withCart as withCartFactory } from '@grandvisionhq/next'

import { featureToggles } from '../../configuration/feature-toggles'

const { isBynderImagesEnabled } = featureToggles

const { publicRuntimeConfig } = getConfig()
const staticsUrl = publicRuntimeConfig.grandvision.staticsUrl

export const settings: CartSettings = {
  staticsUrl,
  hideStockAndDeliveryOnLineItem: true,
  maximumQuantity: 5,
  isDiscountCodeEnabled: true,
  showCartDiscountOnLineItem: true,
  showSubTotal: true,
  showTotalDiscount: true,
  isBynderImagesEnabled,
}

export type { InjectedCartProps }

export const withCart = withCartFactory(settings)
